import revive_payload_client_j8zFKTOS5s from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Elt0tlYTGj from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VfKuOJrHGp from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_StrYNop9oO from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_7zu7ji5XvB from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_TN2vvYIjBh from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_5iKzgY7xHw from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_aHglAvSvwp from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_S5UrH5f7Pn from "/var/www/nuxt/node_modules/.pnpm/@nuxt+ui@2.16.0_qrcode@1.5.3_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_htfDxmCuJ3 from "/var/www/nuxt/node_modules/.pnpm/@nuxt+ui@2.16.0_qrcode@1.5.3_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_XJ1xQcOMP3 from "/var/www/nuxt/node_modules/.pnpm/@nuxt+ui@2.16.0_qrcode@1.5.3_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_bYFa95F1nV from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.21.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_KtdfPbmobd from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_date_picker_AkrrI4qPP9 from "/var/www/nuxt/plugins/vue-date-picker.ts";
export default [
  revive_payload_client_j8zFKTOS5s,
  unhead_Elt0tlYTGj,
  router_VfKuOJrHGp,
  payload_client_StrYNop9oO,
  check_outdated_build_client_7zu7ji5XvB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TN2vvYIjBh,
  composition_5iKzgY7xHw,
  i18n_aHglAvSvwp,
  slideovers_S5UrH5f7Pn,
  modals_htfDxmCuJ3,
  colors_XJ1xQcOMP3,
  plugin_client_bYFa95F1nV,
  chunk_reload_client_KtdfPbmobd,
  vue_date_picker_AkrrI4qPP9
]