export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig()

  const host = await useGetHost()

  const { $i18n } = useNuxtApp()

  const headers: any = {
    'x-app-domain': host,
    'x-app-locale': $i18n.locale.value,
  }

  const sitedata: any = useSiteData()

  if (_isEmpty(sitedata.value)) {
    const { data }: any = await useFetch('/api/egrrfkpqro', {
      baseURL: config.public.apiBase,
      headers,
    })

    sitedata.value = data.value ?? {}
  }
})
