import validate from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authenticated_45global from "/var/www/nuxt/middleware/01.authenticated.global.ts";
import _02_45sitedata_45global from "/var/www/nuxt/middleware/02.sitedata.global.ts";
import manifest_45route_45rule from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5.3.3_vite@5.4.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authenticated_45global,
  _02_45sitedata_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/nuxt/middleware/auth.ts"),
  hidewhenauth: () => import("/var/www/nuxt/middleware/hidewhenauth.ts")
}