import { default as _91_46_46_46slug_936KdSbG6OVcMeta } from "/var/www/nuxt/pages/[...slug].vue?macro=true";
import { default as account_45editzn2jHivM7QMeta } from "/var/www/nuxt/pages/account-edit.vue?macro=true";
import { default as accounts0bPQvL8UkMeta } from "/var/www/nuxt/pages/account.vue?macro=true";
import { default as cartMKYpuLeTyzMeta } from "/var/www/nuxt/pages/cart.vue?macro=true";
import { default as change_45passwordFntYishACEMeta } from "/var/www/nuxt/pages/change-password.vue?macro=true";
import { default as forgot_45passworduglWK1eQxdMeta } from "/var/www/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexarazEwGuWbMeta } from "/var/www/nuxt/pages/index.vue?macro=true";
import { default as login_45qrcodeVTan12nE3OMeta } from "/var/www/nuxt/pages/login-qrcode.vue?macro=true";
import { default as loginuXfvbcCvJdMeta } from "/var/www/nuxt/pages/login.vue?macro=true";
import { default as _91id_93vXqCS9jo3EMeta } from "/var/www/nuxt/pages/order-qrcode/[id].vue?macro=true";
import { default as order_45resultZCSmHk8YOsMeta } from "/var/www/nuxt/pages/order-result.vue?macro=true";
import { default as order_45successnCJfiOBsXiMeta } from "/var/www/nuxt/pages/order-success.vue?macro=true";
import { default as _91id_93o9gmeEBw4XMeta } from "/var/www/nuxt/pages/order/[id].vue?macro=true";
import { default as ordersPy0lMQYICNMeta } from "/var/www/nuxt/pages/orders.vue?macro=true";
import { default as recharge_45logMkYYdLwT5jMeta } from "/var/www/nuxt/pages/recharge-log.vue?macro=true";
import { default as recharge32Yk3XUg7pMeta } from "/var/www/nuxt/pages/recharge.vue?macro=true";
import { default as registerjn2nXMOufyMeta } from "/var/www/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordIxAkDPozMLMeta } from "/var/www/nuxt/pages/reset-password.vue?macro=true";
import { default as _91_46_46_46slug_93D1bNegNMlvMeta } from "/var/www/nuxt/pages/shop/[...slug].vue?macro=true";
import { default as transfer_45logCVsJfXWxo8Meta } from "/var/www/nuxt/pages/transfer-log.vue?macro=true";
import { default as transferVdBoFFmXpBMeta } from "/var/www/nuxt/pages/transfer.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_936KdSbG6OVcMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_936KdSbG6OVcMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_936KdSbG6OVcMeta || {},
    alias: _91_46_46_46slug_936KdSbG6OVcMeta?.alias || [],
    redirect: _91_46_46_46slug_936KdSbG6OVcMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_936KdSbG6OVcMeta?.name ?? "slug___vi___default",
    path: _91_46_46_46slug_936KdSbG6OVcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_936KdSbG6OVcMeta || {},
    alias: _91_46_46_46slug_936KdSbG6OVcMeta?.alias || [],
    redirect: _91_46_46_46slug_936KdSbG6OVcMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_936KdSbG6OVcMeta?.name ?? "slug___vi",
    path: _91_46_46_46slug_936KdSbG6OVcMeta?.path ?? "/vi/:slug(.*)*",
    meta: _91_46_46_46slug_936KdSbG6OVcMeta || {},
    alias: _91_46_46_46slug_936KdSbG6OVcMeta?.alias || [],
    redirect: _91_46_46_46slug_936KdSbG6OVcMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: account_45editzn2jHivM7QMeta?.name ?? "account-edit___en",
    path: account_45editzn2jHivM7QMeta?.path ?? "/en/account-edit",
    meta: account_45editzn2jHivM7QMeta || {},
    alias: account_45editzn2jHivM7QMeta?.alias || [],
    redirect: account_45editzn2jHivM7QMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account-edit.vue").then(m => m.default || m)
  },
  {
    name: account_45editzn2jHivM7QMeta?.name ?? "account-edit___vi___default",
    path: account_45editzn2jHivM7QMeta?.path ?? "/account-edit",
    meta: account_45editzn2jHivM7QMeta || {},
    alias: account_45editzn2jHivM7QMeta?.alias || [],
    redirect: account_45editzn2jHivM7QMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account-edit.vue").then(m => m.default || m)
  },
  {
    name: account_45editzn2jHivM7QMeta?.name ?? "account-edit___vi",
    path: account_45editzn2jHivM7QMeta?.path ?? "/vi/account-edit",
    meta: account_45editzn2jHivM7QMeta || {},
    alias: account_45editzn2jHivM7QMeta?.alias || [],
    redirect: account_45editzn2jHivM7QMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account-edit.vue").then(m => m.default || m)
  },
  {
    name: accounts0bPQvL8UkMeta?.name ?? "account___en",
    path: accounts0bPQvL8UkMeta?.path ?? "/en/account",
    meta: accounts0bPQvL8UkMeta || {},
    alias: accounts0bPQvL8UkMeta?.alias || [],
    redirect: accounts0bPQvL8UkMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accounts0bPQvL8UkMeta?.name ?? "account___vi___default",
    path: accounts0bPQvL8UkMeta?.path ?? "/account",
    meta: accounts0bPQvL8UkMeta || {},
    alias: accounts0bPQvL8UkMeta?.alias || [],
    redirect: accounts0bPQvL8UkMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accounts0bPQvL8UkMeta?.name ?? "account___vi",
    path: accounts0bPQvL8UkMeta?.path ?? "/vi/account",
    meta: accounts0bPQvL8UkMeta || {},
    alias: accounts0bPQvL8UkMeta?.alias || [],
    redirect: accounts0bPQvL8UkMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/account.vue").then(m => m.default || m)
  },
  {
    name: cartMKYpuLeTyzMeta?.name ?? "cart___en",
    path: cartMKYpuLeTyzMeta?.path ?? "/en/cart",
    meta: cartMKYpuLeTyzMeta || {},
    alias: cartMKYpuLeTyzMeta?.alias || [],
    redirect: cartMKYpuLeTyzMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartMKYpuLeTyzMeta?.name ?? "cart___vi___default",
    path: cartMKYpuLeTyzMeta?.path ?? "/cart",
    meta: cartMKYpuLeTyzMeta || {},
    alias: cartMKYpuLeTyzMeta?.alias || [],
    redirect: cartMKYpuLeTyzMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartMKYpuLeTyzMeta?.name ?? "cart___vi",
    path: cartMKYpuLeTyzMeta?.path ?? "/vi/cart",
    meta: cartMKYpuLeTyzMeta || {},
    alias: cartMKYpuLeTyzMeta?.alias || [],
    redirect: cartMKYpuLeTyzMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordFntYishACEMeta?.name ?? "change-password___en",
    path: change_45passwordFntYishACEMeta?.path ?? "/en/change-password",
    meta: change_45passwordFntYishACEMeta || {},
    alias: change_45passwordFntYishACEMeta?.alias || [],
    redirect: change_45passwordFntYishACEMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordFntYishACEMeta?.name ?? "change-password___vi___default",
    path: change_45passwordFntYishACEMeta?.path ?? "/change-password",
    meta: change_45passwordFntYishACEMeta || {},
    alias: change_45passwordFntYishACEMeta?.alias || [],
    redirect: change_45passwordFntYishACEMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordFntYishACEMeta?.name ?? "change-password___vi",
    path: change_45passwordFntYishACEMeta?.path ?? "/vi/change-password",
    meta: change_45passwordFntYishACEMeta || {},
    alias: change_45passwordFntYishACEMeta?.alias || [],
    redirect: change_45passwordFntYishACEMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passworduglWK1eQxdMeta?.name ?? "forgot-password___en",
    path: forgot_45passworduglWK1eQxdMeta?.path ?? "/en/forgot-password",
    meta: forgot_45passworduglWK1eQxdMeta || {},
    alias: forgot_45passworduglWK1eQxdMeta?.alias || [],
    redirect: forgot_45passworduglWK1eQxdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passworduglWK1eQxdMeta?.name ?? "forgot-password___vi___default",
    path: forgot_45passworduglWK1eQxdMeta?.path ?? "/forgot-password",
    meta: forgot_45passworduglWK1eQxdMeta || {},
    alias: forgot_45passworduglWK1eQxdMeta?.alias || [],
    redirect: forgot_45passworduglWK1eQxdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passworduglWK1eQxdMeta?.name ?? "forgot-password___vi",
    path: forgot_45passworduglWK1eQxdMeta?.path ?? "/vi/forgot-password",
    meta: forgot_45passworduglWK1eQxdMeta || {},
    alias: forgot_45passworduglWK1eQxdMeta?.alias || [],
    redirect: forgot_45passworduglWK1eQxdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexarazEwGuWbMeta?.name ?? "index___en",
    path: indexarazEwGuWbMeta?.path ?? "/en",
    meta: indexarazEwGuWbMeta || {},
    alias: indexarazEwGuWbMeta?.alias || [],
    redirect: indexarazEwGuWbMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexarazEwGuWbMeta?.name ?? "index___vi___default",
    path: indexarazEwGuWbMeta?.path ?? "/",
    meta: indexarazEwGuWbMeta || {},
    alias: indexarazEwGuWbMeta?.alias || [],
    redirect: indexarazEwGuWbMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexarazEwGuWbMeta?.name ?? "index___vi",
    path: indexarazEwGuWbMeta?.path ?? "/vi",
    meta: indexarazEwGuWbMeta || {},
    alias: indexarazEwGuWbMeta?.alias || [],
    redirect: indexarazEwGuWbMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login_45qrcodeVTan12nE3OMeta?.name ?? "login-qrcode___en",
    path: login_45qrcodeVTan12nE3OMeta?.path ?? "/en/login-qrcode",
    meta: login_45qrcodeVTan12nE3OMeta || {},
    alias: login_45qrcodeVTan12nE3OMeta?.alias || [],
    redirect: login_45qrcodeVTan12nE3OMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login-qrcode.vue").then(m => m.default || m)
  },
  {
    name: login_45qrcodeVTan12nE3OMeta?.name ?? "login-qrcode___vi___default",
    path: login_45qrcodeVTan12nE3OMeta?.path ?? "/login-qrcode",
    meta: login_45qrcodeVTan12nE3OMeta || {},
    alias: login_45qrcodeVTan12nE3OMeta?.alias || [],
    redirect: login_45qrcodeVTan12nE3OMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login-qrcode.vue").then(m => m.default || m)
  },
  {
    name: login_45qrcodeVTan12nE3OMeta?.name ?? "login-qrcode___vi",
    path: login_45qrcodeVTan12nE3OMeta?.path ?? "/vi/login-qrcode",
    meta: login_45qrcodeVTan12nE3OMeta || {},
    alias: login_45qrcodeVTan12nE3OMeta?.alias || [],
    redirect: login_45qrcodeVTan12nE3OMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login-qrcode.vue").then(m => m.default || m)
  },
  {
    name: loginuXfvbcCvJdMeta?.name ?? "login___en",
    path: loginuXfvbcCvJdMeta?.path ?? "/en/login",
    meta: loginuXfvbcCvJdMeta || {},
    alias: loginuXfvbcCvJdMeta?.alias || [],
    redirect: loginuXfvbcCvJdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuXfvbcCvJdMeta?.name ?? "login___vi___default",
    path: loginuXfvbcCvJdMeta?.path ?? "/login",
    meta: loginuXfvbcCvJdMeta || {},
    alias: loginuXfvbcCvJdMeta?.alias || [],
    redirect: loginuXfvbcCvJdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuXfvbcCvJdMeta?.name ?? "login___vi",
    path: loginuXfvbcCvJdMeta?.path ?? "/vi/login",
    meta: loginuXfvbcCvJdMeta || {},
    alias: loginuXfvbcCvJdMeta?.alias || [],
    redirect: loginuXfvbcCvJdMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vXqCS9jo3EMeta?.name ?? "order-qrcode-id___en",
    path: _91id_93vXqCS9jo3EMeta?.path ?? "/en/order-qrcode/:id()",
    meta: _91id_93vXqCS9jo3EMeta || {},
    alias: _91id_93vXqCS9jo3EMeta?.alias || [],
    redirect: _91id_93vXqCS9jo3EMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vXqCS9jo3EMeta?.name ?? "order-qrcode-id___vi___default",
    path: _91id_93vXqCS9jo3EMeta?.path ?? "/order-qrcode/:id()",
    meta: _91id_93vXqCS9jo3EMeta || {},
    alias: _91id_93vXqCS9jo3EMeta?.alias || [],
    redirect: _91id_93vXqCS9jo3EMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vXqCS9jo3EMeta?.name ?? "order-qrcode-id___vi",
    path: _91id_93vXqCS9jo3EMeta?.path ?? "/vi/order-qrcode/:id()",
    meta: _91id_93vXqCS9jo3EMeta || {},
    alias: _91id_93vXqCS9jo3EMeta?.alias || [],
    redirect: _91id_93vXqCS9jo3EMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-qrcode/[id].vue").then(m => m.default || m)
  },
  {
    name: order_45resultZCSmHk8YOsMeta?.name ?? "order-result___en",
    path: order_45resultZCSmHk8YOsMeta?.path ?? "/en/order-result",
    meta: order_45resultZCSmHk8YOsMeta || {},
    alias: order_45resultZCSmHk8YOsMeta?.alias || [],
    redirect: order_45resultZCSmHk8YOsMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-result.vue").then(m => m.default || m)
  },
  {
    name: order_45resultZCSmHk8YOsMeta?.name ?? "order-result___vi___default",
    path: order_45resultZCSmHk8YOsMeta?.path ?? "/order-result",
    meta: order_45resultZCSmHk8YOsMeta || {},
    alias: order_45resultZCSmHk8YOsMeta?.alias || [],
    redirect: order_45resultZCSmHk8YOsMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-result.vue").then(m => m.default || m)
  },
  {
    name: order_45resultZCSmHk8YOsMeta?.name ?? "order-result___vi",
    path: order_45resultZCSmHk8YOsMeta?.path ?? "/vi/order-result",
    meta: order_45resultZCSmHk8YOsMeta || {},
    alias: order_45resultZCSmHk8YOsMeta?.alias || [],
    redirect: order_45resultZCSmHk8YOsMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-result.vue").then(m => m.default || m)
  },
  {
    name: order_45successnCJfiOBsXiMeta?.name ?? "order-success___en",
    path: order_45successnCJfiOBsXiMeta?.path ?? "/en/order-success",
    meta: order_45successnCJfiOBsXiMeta || {},
    alias: order_45successnCJfiOBsXiMeta?.alias || [],
    redirect: order_45successnCJfiOBsXiMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-success.vue").then(m => m.default || m)
  },
  {
    name: order_45successnCJfiOBsXiMeta?.name ?? "order-success___vi___default",
    path: order_45successnCJfiOBsXiMeta?.path ?? "/order-success",
    meta: order_45successnCJfiOBsXiMeta || {},
    alias: order_45successnCJfiOBsXiMeta?.alias || [],
    redirect: order_45successnCJfiOBsXiMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-success.vue").then(m => m.default || m)
  },
  {
    name: order_45successnCJfiOBsXiMeta?.name ?? "order-success___vi",
    path: order_45successnCJfiOBsXiMeta?.path ?? "/vi/order-success",
    meta: order_45successnCJfiOBsXiMeta || {},
    alias: order_45successnCJfiOBsXiMeta?.alias || [],
    redirect: order_45successnCJfiOBsXiMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order-success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93o9gmeEBw4XMeta?.name ?? "order-id___en",
    path: _91id_93o9gmeEBw4XMeta?.path ?? "/en/order/:id()",
    meta: _91id_93o9gmeEBw4XMeta || {},
    alias: _91id_93o9gmeEBw4XMeta?.alias || [],
    redirect: _91id_93o9gmeEBw4XMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93o9gmeEBw4XMeta?.name ?? "order-id___vi___default",
    path: _91id_93o9gmeEBw4XMeta?.path ?? "/order/:id()",
    meta: _91id_93o9gmeEBw4XMeta || {},
    alias: _91id_93o9gmeEBw4XMeta?.alias || [],
    redirect: _91id_93o9gmeEBw4XMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93o9gmeEBw4XMeta?.name ?? "order-id___vi",
    path: _91id_93o9gmeEBw4XMeta?.path ?? "/vi/order/:id()",
    meta: _91id_93o9gmeEBw4XMeta || {},
    alias: _91id_93o9gmeEBw4XMeta?.alias || [],
    redirect: _91id_93o9gmeEBw4XMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: ordersPy0lMQYICNMeta?.name ?? "orders___en",
    path: ordersPy0lMQYICNMeta?.path ?? "/en/orders",
    meta: ordersPy0lMQYICNMeta || {},
    alias: ordersPy0lMQYICNMeta?.alias || [],
    redirect: ordersPy0lMQYICNMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersPy0lMQYICNMeta?.name ?? "orders___vi___default",
    path: ordersPy0lMQYICNMeta?.path ?? "/orders",
    meta: ordersPy0lMQYICNMeta || {},
    alias: ordersPy0lMQYICNMeta?.alias || [],
    redirect: ordersPy0lMQYICNMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersPy0lMQYICNMeta?.name ?? "orders___vi",
    path: ordersPy0lMQYICNMeta?.path ?? "/vi/orders",
    meta: ordersPy0lMQYICNMeta || {},
    alias: ordersPy0lMQYICNMeta?.alias || [],
    redirect: ordersPy0lMQYICNMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: recharge_45logMkYYdLwT5jMeta?.name ?? "recharge-log___en",
    path: recharge_45logMkYYdLwT5jMeta?.path ?? "/en/recharge-log",
    meta: recharge_45logMkYYdLwT5jMeta || {},
    alias: recharge_45logMkYYdLwT5jMeta?.alias || [],
    redirect: recharge_45logMkYYdLwT5jMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge-log.vue").then(m => m.default || m)
  },
  {
    name: recharge_45logMkYYdLwT5jMeta?.name ?? "recharge-log___vi___default",
    path: recharge_45logMkYYdLwT5jMeta?.path ?? "/recharge-log",
    meta: recharge_45logMkYYdLwT5jMeta || {},
    alias: recharge_45logMkYYdLwT5jMeta?.alias || [],
    redirect: recharge_45logMkYYdLwT5jMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge-log.vue").then(m => m.default || m)
  },
  {
    name: recharge_45logMkYYdLwT5jMeta?.name ?? "recharge-log___vi",
    path: recharge_45logMkYYdLwT5jMeta?.path ?? "/vi/recharge-log",
    meta: recharge_45logMkYYdLwT5jMeta || {},
    alias: recharge_45logMkYYdLwT5jMeta?.alias || [],
    redirect: recharge_45logMkYYdLwT5jMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge-log.vue").then(m => m.default || m)
  },
  {
    name: recharge32Yk3XUg7pMeta?.name ?? "recharge___en",
    path: recharge32Yk3XUg7pMeta?.path ?? "/en/recharge",
    meta: recharge32Yk3XUg7pMeta || {},
    alias: recharge32Yk3XUg7pMeta?.alias || [],
    redirect: recharge32Yk3XUg7pMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge.vue").then(m => m.default || m)
  },
  {
    name: recharge32Yk3XUg7pMeta?.name ?? "recharge___vi___default",
    path: recharge32Yk3XUg7pMeta?.path ?? "/recharge",
    meta: recharge32Yk3XUg7pMeta || {},
    alias: recharge32Yk3XUg7pMeta?.alias || [],
    redirect: recharge32Yk3XUg7pMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge.vue").then(m => m.default || m)
  },
  {
    name: recharge32Yk3XUg7pMeta?.name ?? "recharge___vi",
    path: recharge32Yk3XUg7pMeta?.path ?? "/vi/recharge",
    meta: recharge32Yk3XUg7pMeta || {},
    alias: recharge32Yk3XUg7pMeta?.alias || [],
    redirect: recharge32Yk3XUg7pMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/recharge.vue").then(m => m.default || m)
  },
  {
    name: registerjn2nXMOufyMeta?.name ?? "register___en",
    path: registerjn2nXMOufyMeta?.path ?? "/en/register",
    meta: registerjn2nXMOufyMeta || {},
    alias: registerjn2nXMOufyMeta?.alias || [],
    redirect: registerjn2nXMOufyMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerjn2nXMOufyMeta?.name ?? "register___vi___default",
    path: registerjn2nXMOufyMeta?.path ?? "/register",
    meta: registerjn2nXMOufyMeta || {},
    alias: registerjn2nXMOufyMeta?.alias || [],
    redirect: registerjn2nXMOufyMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerjn2nXMOufyMeta?.name ?? "register___vi",
    path: registerjn2nXMOufyMeta?.path ?? "/vi/register",
    meta: registerjn2nXMOufyMeta || {},
    alias: registerjn2nXMOufyMeta?.alias || [],
    redirect: registerjn2nXMOufyMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordIxAkDPozMLMeta?.name ?? "reset-password___en",
    path: reset_45passwordIxAkDPozMLMeta?.path ?? "/en/reset-password",
    meta: reset_45passwordIxAkDPozMLMeta || {},
    alias: reset_45passwordIxAkDPozMLMeta?.alias || [],
    redirect: reset_45passwordIxAkDPozMLMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordIxAkDPozMLMeta?.name ?? "reset-password___vi___default",
    path: reset_45passwordIxAkDPozMLMeta?.path ?? "/reset-password",
    meta: reset_45passwordIxAkDPozMLMeta || {},
    alias: reset_45passwordIxAkDPozMLMeta?.alias || [],
    redirect: reset_45passwordIxAkDPozMLMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordIxAkDPozMLMeta?.name ?? "reset-password___vi",
    path: reset_45passwordIxAkDPozMLMeta?.path ?? "/vi/reset-password",
    meta: reset_45passwordIxAkDPozMLMeta || {},
    alias: reset_45passwordIxAkDPozMLMeta?.alias || [],
    redirect: reset_45passwordIxAkDPozMLMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93D1bNegNMlvMeta?.name ?? "shop-slug___en",
    path: _91_46_46_46slug_93D1bNegNMlvMeta?.path ?? "/en/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93D1bNegNMlvMeta || {},
    alias: _91_46_46_46slug_93D1bNegNMlvMeta?.alias || [],
    redirect: _91_46_46_46slug_93D1bNegNMlvMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93D1bNegNMlvMeta?.name ?? "shop-slug___vi___default",
    path: _91_46_46_46slug_93D1bNegNMlvMeta?.path ?? "/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93D1bNegNMlvMeta || {},
    alias: _91_46_46_46slug_93D1bNegNMlvMeta?.alias || [],
    redirect: _91_46_46_46slug_93D1bNegNMlvMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93D1bNegNMlvMeta?.name ?? "shop-slug___vi",
    path: _91_46_46_46slug_93D1bNegNMlvMeta?.path ?? "/vi/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93D1bNegNMlvMeta || {},
    alias: _91_46_46_46slug_93D1bNegNMlvMeta?.alias || [],
    redirect: _91_46_46_46slug_93D1bNegNMlvMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: transfer_45logCVsJfXWxo8Meta?.name ?? "transfer-log___en",
    path: transfer_45logCVsJfXWxo8Meta?.path ?? "/en/transfer-log",
    meta: transfer_45logCVsJfXWxo8Meta || {},
    alias: transfer_45logCVsJfXWxo8Meta?.alias || [],
    redirect: transfer_45logCVsJfXWxo8Meta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer-log.vue").then(m => m.default || m)
  },
  {
    name: transfer_45logCVsJfXWxo8Meta?.name ?? "transfer-log___vi___default",
    path: transfer_45logCVsJfXWxo8Meta?.path ?? "/transfer-log",
    meta: transfer_45logCVsJfXWxo8Meta || {},
    alias: transfer_45logCVsJfXWxo8Meta?.alias || [],
    redirect: transfer_45logCVsJfXWxo8Meta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer-log.vue").then(m => m.default || m)
  },
  {
    name: transfer_45logCVsJfXWxo8Meta?.name ?? "transfer-log___vi",
    path: transfer_45logCVsJfXWxo8Meta?.path ?? "/vi/transfer-log",
    meta: transfer_45logCVsJfXWxo8Meta || {},
    alias: transfer_45logCVsJfXWxo8Meta?.alias || [],
    redirect: transfer_45logCVsJfXWxo8Meta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer-log.vue").then(m => m.default || m)
  },
  {
    name: transferVdBoFFmXpBMeta?.name ?? "transfer___en",
    path: transferVdBoFFmXpBMeta?.path ?? "/en/transfer",
    meta: transferVdBoFFmXpBMeta || {},
    alias: transferVdBoFFmXpBMeta?.alias || [],
    redirect: transferVdBoFFmXpBMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer.vue").then(m => m.default || m)
  },
  {
    name: transferVdBoFFmXpBMeta?.name ?? "transfer___vi___default",
    path: transferVdBoFFmXpBMeta?.path ?? "/transfer",
    meta: transferVdBoFFmXpBMeta || {},
    alias: transferVdBoFFmXpBMeta?.alias || [],
    redirect: transferVdBoFFmXpBMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer.vue").then(m => m.default || m)
  },
  {
    name: transferVdBoFFmXpBMeta?.name ?? "transfer___vi",
    path: transferVdBoFFmXpBMeta?.path ?? "/vi/transfer",
    meta: transferVdBoFFmXpBMeta || {},
    alias: transferVdBoFFmXpBMeta?.alias || [],
    redirect: transferVdBoFFmXpBMeta?.redirect,
    component: () => import("/var/www/nuxt/pages/transfer.vue").then(m => m.default || m)
  }
]